import { refreshAdsSlot } from '@/helpers/index';
import styles from 'styles/components/ads/mobileStickyAd.module.css';
import { Ad } from '.';

export default function MobileStickyAd() {
  return (
    <div className={styles.cover}>
      <div className={styles.container}>
        <Ad
          path="/92492279/Mobile_Sticky_AdSlot"
          id="mobile-sticky-ad"
          dimensions={[
            [320, 50],
            [1, 1],
          ]}
          isCollapseEmptyDivs={false}
          onSlotRenderEnded={(e) => refreshAdsSlot(e.slot)}
        />
      </div>
    </div>
  );
}
