import { get } from 'lodash';
import { useEffect, useLayoutEffect, useRef } from 'react';

export function adContextCategory(category) {
  if (category) {
    try {
      window.googletag = window.googletag || { cmd: [] };
      googletag.cmd.push(function () {
        googletag
          .pubads()
          .setTargeting('interests', [
            ...category.split(','),
            'Halal',
            'Rooms',
            'Hotel',
            'Hotels',
            'Wudhu-friendly nail polish',
            'Wellness resorts',
            'Ultimate guide',
            'Travel strollers',
            'Travel guide',
          ])
          .setTargeting('ContextualTargeting', [
            ...category.split(','),
            'Halal',
            'Rooms',
            'Hotel',
            'Hotels',
            'Wudhu-friendly nail polish',
            'Wellness resorts',
            'Ultimate guide',
            'Travel strollers',
            'Travel guide',
          ]);
      });
    } catch (err) {}
  }
}

export function Ad({
  id,
  dimensions,
  path,
  isCollapseEmptyDivs,
  onSlotRenderEnded,
}) {
  const container = useRef();

  useEffect(() => {
    const sizes = dimensions?.filter((size) => {
      if (Array.isArray(size)) return size[0] < window.innerWidth;
      return true;
    });

    const googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(function () {
      const slot = googletag.defineSlot(path, sizes, id);

      if (slot) {
        slot.addService(googletag.pubads()).setTargeting('ads', 'lazyload');
        googletag.pubads().enableLazyLoad();
        googletag
          .pubads()
          .addEventListener('slotRenderEnded', function (event) {
            const slotId = event.slot.getSlotElementId();

            if (id === slotId && onSlotRenderEnded) {
              onSlotRenderEnded(event);
            }
          });

        if (isCollapseEmptyDivs) googletag.pubads().collapseEmptyDivs();

        googletag.pubads().disableInitialLoad();
        googletag.enableServices();

        googletag.display(id);
      } else {
        console.error('Failed to define slot');
      }
    });
  }, []);

  return <div ref={container} id={id} data-ad="true"></div>;
}

export function WideAd({
  path,
  dimensions,
  id,
  onSlotRequested,
  onSlotResponseReceived,
  onSlotLoad,
  onSlotRenderEnded,
}) {
  let container = useRef();

  useLayoutEffect(() => {
    try {
      window.googletag = window.googletag || { cmd: [] };

      googletag.cmd.push(function () {
        let adslot = googletag
          .defineSlot(path, dimensions, id)
          .addService(googletag.pubads());

        if (onSlotLoad)
          googletag.pubads().addEventListener('slotOnload', function (e) {
            if (id == e?.slot?.getSlotElementId()) onSlotLoad(e);
          });
        if (onSlotRenderEnded)
          googletag.pubads().addEventListener('slotRenderEnded', function (e) {
            if (id == e?.slot?.getSlotElementId()) onSlotRenderEnded(e);
          });
        if (onSlotRequested)
          googletag.pubads().addEventListener('slotRequested', function (e) {
            if (id == e?.slot?.getSlotElementId()) onSlotRequested(e);
          });
        if (onSlotResponseReceived)
          googletag
            .pubads()
            .addEventListener('slotResponseReceived', function (e) {
              if (id == e?.slot?.getSlotElementId()) onSlotResponseReceive(e);
            });

        let mapping = googletag
          .sizeMapping()
          .addSize(
            [1100, 0],
            [
              [1, 1],
              [728, 90],
              [970, 90],
              [970, 250],
            ]
          )
          .addSize(
            [640, 0],
            [
              [728, 90],
              [1, 1],
            ]
          )
          .build();
        adslot.defineSizeMapping(mapping);

        googletag.pubads().collapseEmptyDivs();
        googletag.enableServices();
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      googletag.cmd.push(function () {
        googletag.display(id);
      });
    } catch (err) {}
  }, []);

  return <div ref={container} id={id}></div>;
}

export function NormalAd({
  path,
  dimensions,
  id,
  onSlotRequested,
  onSlotResponseReceived,
  onSlotLoad,
  onSlotRenderEnded,
}) {
  useLayoutEffect(() => {
    try {
      window.googletag = window.googletag || { cmd: [] };

      googletag.cmd.push(function () {
        googletag
          .defineSlot(path, dimensions, id)
          .addService(googletag.pubads());

        if (onSlotLoad)
          googletag.pubads().addEventListener('slotOnload', function (e) {
            if (id == e?.slot?.getSlotElementId()) onSlotLoad(e);
          });
        if (onSlotRenderEnded)
          googletag.pubads().addEventListener('slotRenderEnded', function (e) {
            if (id == e?.slot?.getSlotElementId()) onSlotRenderEnded(e);
          });
        if (onSlotRequested)
          googletag.pubads().addEventListener('slotRequested', function (e) {
            if (id == e?.slot?.getSlotElementId()) onSlotRequested(e);
          });
        if (onSlotResponseReceived)
          googletag
            .pubads()
            .addEventListener('slotResponseReceived', function (e) {
              if (id == e?.slot?.getSlotElementId()) onSlotResponseReceive(e);
            });

        googletag.pubads().collapseEmptyDivs();
        googletag.enableServices();

        googletag.display(id);
      });
    } catch (error) {}
  }, []);

  return <div id={id}></div>;
}

export function AdsLazy({
  id,
  dimensions,
  path,
  isCollapseEmptyDivs,
  onSlotRenderEnded,
}) {
  const container = useRef();

  useEffect(() => {
    try {
      const sizes = dimensions?.filter((size) => {
        if (Array.isArray(size)) return size[0] < window.innerWidth;
        return true;
      });

      const googletag = window.googletag || { cmd: [] };

      googletag.cmd.push(function () {
        googletag
          .defineSlot(path, sizes, id)
          .addService(googletag.pubads())
          .setTargeting('ads', 'lazyload');

        // Config Scroll lazyloading
        googletag.pubads().enableLazyLoad();

        // Check Ads isEmpty
        googletag
          .pubads()
          .addEventListener('slotRenderEnded', function (event) {
            const slotId = event.slot.getSlotElementId();

            if (id === slotId && onSlotRenderEnded) onSlotRenderEnded(event);
          });

        if (isCollapseEmptyDivs) googletag.pubads().collapseEmptyDivs();

        googletag.pubads().disableInitialLoad();
        googletag.enableServices();

        googletag.display(id);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return <div ref={container} id={id} data-ad="true"></div>;
}

export function removeSlots(slot) {
  const { googletag } = window;
  googletag.cmd.push(function () {
    if (slot) googletag.destroySlots([slot]);
    else googletag.destroySlots();
  });
}

export function refreshSlots(slot) {
  const { googletag } = window;
  googletag.cmd.push(function () {
    if (slot) googletag.pubads().refresh([slot]);
    else googletag.pubads().refresh();
  });
}

export function clearAllSlots() {
  const { googletag } = window;
  googletag.cmd.push(function () {
    googletag.pubads().clear();
  });
}
